<template>
  <auth>
    <template #auth-title>
      Forgotten password
    </template>

    <template #form>
      <b-field
        label="Email"
        class="mb-4"
      >
        <b-input
          v-model="auth.email"
          icon="email"
          type="text"
          placeholder="max.mustermann@example.de"
          @keyup.native.enter="forgotPassword()"
        />
      </b-field>
    </template>

    <template
      v-if="isRequestSent"
      #success-message
    >
      Email was successfully sent.
    </template>

    <template #auth-additional-options>
      <div class="column is-12">
        <router-link
          class="is-pulled-right is-size-7"
          to="/login"
        >
          Back to login
        </router-link>
      </div>
    </template>

    <template #action-button>
      <b-button
        expanded
        type="is-primary"
        @click="forgotPassword()"
      >
        Send reset password request
      </b-button>
    </template>

    <template #info />
  </auth>
</template>

<script>
import { forgotPasswordService } from '@/services/auth-service/authRequests';
import { ref, reactive } from '@vue/composition-api';
import Auth from './../components/Auth';

export default {
    components: {
        Auth
    },
    setup () {
        /***** forgot password logic start *****/
        const isRequestSent = ref(false);
        const auth = reactive({
            email: ''
        });
        const forgotPassword = async() => {
            try {
                isRequestSent.value = false;
                await forgotPasswordService(auth);
                isRequestSent.value = true;
            } catch (err) {
                console.error(err);
            }
        };
        /** logic exposed to template */
        const exposedForgotPassswordLogic = {
            isRequestSent,
            auth,
            forgotPassword
        };
        /***** forgot password logic end *****/

        return {
            ...exposedForgotPassswordLogic
        };
    }
};
</script>